/* stylelint-disable selector-class-pattern */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.body {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background: rgb(0 0 0 / 70%) !important;
}

.body::-webkit-scrollbar {
  width: 0;
}

.box {
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translate(-50%);
  overflow: hidden;
  width: 100%;
  max-width: 380px;
  height: 550px;
  background: #1c1c1c;
  border-radius: 8px;
}

.box::before {
  content: "";
  z-index: 1;
  position: absolute;
  top: -50%;
  left: -50%;
  width: 380px;
  height: 680px;
  transform-origin: bottom right;
  background: linear-gradient(0deg, transparent, #fdae5c, #45f3ff);
  animation: animate 6s linear infinite;
}

.box::after {
  content: "";
  z-index: 1;
  position: absolute;
  top: -50%;
  left: -50%;
  width: 380px;
  height: 680px;
  transform-origin: bottom right;
  background: linear-gradient(0deg, transparent, #45f3ff, #45f3ff);
  animation: animate 6s linear infinite;
  animation-delay: -3s;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.box .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
  font-size: 20px;
  background-color: transparent;
  padding: 2px 7px;
  color: #fdae5c;
  border: none;
  z-index: 44 !important;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 5px;
}

.links button {
  margin: 10px 0;
  font-size: 0.75em;
  color: #8f8f8f;
  text-decoration: beige;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
}

.box button:hover {
  box-shadow: inset 1px 1px 2px #fdae5c, 1px 1px 5px #fdae5c;
}

form {
  position: absolute;
  inset: 2px;
  background: #28292d;
  padding: 50px 40px;
  border-radius: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
}

h2 {
  color: #fff;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.1em;
}

.inputBox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.social-login-btn i {
  color: #fdae5c;
  border: 1px solid;
  transition: 0.3s;
  cursor: pointer;
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  box-shadow: inset 1px 1px 2px #fdae5c, 1px 1px 5px #fdae5c;
}

.social-login-btn i:hover {
  transform: scale(1.1);
}

.inputBox {
  position: relative;
  width: 300px;
  margin-top: 35px;
}

.inputBox input {
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
}

.inputBox span {
  position: absolute;
  z-index: 10;
  left: 0;
  padding: 20px 0 10px;
  pointer-events: none;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 0.05em;
  transition: 0.5s;
}

.inputBox svg {
  position: absolute;
  z-index: 100;
  right: 0;
  padding: 20px 0 10px;
  cursor: pointer;
  font-size: 3em;
  color: #8f8f8f;
  transition: 1.5s;
}

.inputBox input:valid ~ span,
.inputBox input:focus ~ span {
  color: #fdae5c;
  transform: translateX(0) translateY(-34px);
  font-size: 0.75em;
}

.inputBox input:valid ~ i,
.inputBox input:focus ~ i {
  height: 44px;
}

.links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.links p {
  color: #fdae5c;
  font-size: 12px;
}

.links button:hover {
  color: #fdae5c;
}

input[type="submit"],
input[type="button"] {
  margin-top: 20px;
  padding: 10px 40px;

  /* border-radius: 2rem 0 2rem 0;  */
  border-radius: 50px;
  font-size: 17px;
  border: none;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.5px;
  cursor: pointer;
  color: #fdae5c;
  background-color: #23242a;
  transition: 0.3s;
  text-align: center;
  box-shadow: inset 1px 1px 2px #fdae5c, 1px 1px 5px #fdae5c;
}

input[type="submit"]:hover {
  background-color: #fdae5c;
  color: rgb(0 0 0);
}

input[type="submit"]:active {
  opacity: 0.8;
}

.social-login-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.errormessage {
  color: #f00;
}

/* Media Query */

/* @media screen and (max-width:1024px) {
    .box {
        margin-top: 5rem;
        padding-bottom: 10px;
    }
} */

/* @media screen and (max-width:480px) {
    .box {
    
    }
} */

/* @media screen and (max-width:375px) {
    .box {
        width: 360px;
        margin-top: 20px;
        padding-top: 20rem;
    }
} */
