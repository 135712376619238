.agents {
  padding: 2rem 1rem 4rem;
  display: grid;
  place-items: center;
}

.agents-heading {
  text-align: center;
}

.agents-heading h2 {
  font-size: 2.2rem;
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  padding-top: 2rem;
  color: rgb(0 0 0 / 80%);
}

.agents-heading span {
  color: #fdae5c;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  position: relative;
  font-family: Poppins, sans-serif;
}

.agents-heading span::before {
  position: absolute;
  top: 50%;
  right: -60px;
  content: "";
  width: 50px;
  height: 1px;
  background: #fdae5c;
}

.agents-heading span::after {
  position: absolute;
  top: 50%;
  left: -60px;
  content: "";
  width: 50px;
  height: 1px;
  background: #fdae5c;
}

.agents-row {
  padding-top: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.agents-column {
  padding-left: 20px;
  padding-right: 20px;
}

.single-agent {
  margin-bottom: 30px;
}

.agent-thumb {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}

.agent-thumb img {
  object-fit: cover;
  object-position: center;
  width: 250px;
  height: 270px;
  border-radius: 10px;
  transition: 0.3s;
}

.agents-column:hover .agent-thumb img {
  transform: scale(1.1);
}

.agent-thumb .social-link {
  position: absolute;
  bottom: 0;
  width: 250px;
  background: rgb(0 29 56 / 50%);
  border-radius: 10px;
  text-align: center;
  padding: 13px 0;
  transition: 0.5s;
  transform: translateY(100%) scale(1.2);
  opacity: 0;
  visibility: hidden;
}

.agents-column:hover .social-link {
  transform: translateY(0%) scale(1);
  opacity: 1;
  visibility: visible;
}

.agent-thumb .social-link ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.agent-thumb .social-link li a {
  font-size: 15px;
  color: #c7c7c7;
  margin: 0 6px;
}

.agent-info {
  text-align: center;
}

.agent-info h3 {
  padding-top: 1rem;
  font-size: 22px;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  color: #2c2c2c;
  margin-bottom: 0;
}

.agent-info p {
  font-size: 13px;
  font-weight: 300;
  font-family: Poppins, sans-serif;
  color: #2c2c2c;
  margin-top: 6px;
  margin-bottom: 8px;
}

@media screen and (width <=1200px) {
  .agents-column {
    padding-left: 15px;
    padding-right: 15px;
  }

  .agent-thumb img {
    width: 200px;
    height: 250px;
  }

  .agent-thumb .social-link {
    width: 200px;
  }
}

@media screen and (width <=948px) {
  .agents-row {
    grid-template-columns: 1fr 1fr;
  }

  .agents-column {
    padding: 0 1rem 3rem;
  }

  .agent-thumb img {
    width: 300px;
    height: 300px;
  }

  .agent-thumb .social-link {
    width: 300px;
  }
}

@media screen and (width <=768px) {
  .agents {
    padding: 1rem;
  }

  .agents-row {
    grid-template-columns: 1fr;
  }

  .agent-thumb img {
    width: 450px;
    height: 400px;
  }

  .agent-thumb .social-link {
    width: 450px;
  }
}

@media screen and (width <=500px) {
  .agents-column {
    padding: 0 0 3rem;
  }

  .agent-thumb img {
    width: 400px;
    height: 400px;
  }

  .agent-thumb .social-link {
    width: 400px;
  }
}

@media screen and (width <=440px) {
  .agent-thumb img {
    width: 350px;
    height: 400px;
  }

  .agent-thumb .social-link {
    width: 350px;
  }
}

@media screen and (width <=385px) {
  .agent-thumb img {
    width: 300px;
    height: 350px;
  }

  .agent-thumb .social-link {
    width: 300px;
  }
}

@media screen and (width <=321px) {
  .agent-thumb img {
    width: 270px;
    height: 300px;
  }

  .agent-thumb .social-link {
    width: 270px;
  }
}

@media screen and (width <=291px) {
  .agent-thumb img {
    width: 230px;
    height: 270px;
  }

  .agent-thumb .social-link {
    width: 230px;
  }
}
