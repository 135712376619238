.home {
  display: flex;
  padding-top: 13rem;
  padding-left: 5rem;
  background-image: url("../../images/colton-sturgeon-6KkYYqTEDwQ-unsplash.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 100vh;
}

.home::before {
  content: "";
  position: absolute;
  inset: 0;
  background: #001d38;
  opacity: 0.6;
}

.container {
  position: relative;
}

.container h1 {
  color: white;
  font-size: 4rem;
  line-height: 1.3;
  margin: 10px 0 20px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-family: "Nuosu SIL", serif;
}

.container p {
  font-weight: 400;
  width: 500px;
  letter-spacing: 1px;
  line-height: 1.7;
  font-family: "Nuosu SIL", serif;
  color: white;
}

.link {
  display: flex;
  margin-top: 2rem;
}

.link a {
  margin-right: 20px;
  padding: 12px 40px;
  background-color: #fdae5c;
  border-radius: 2rem 0;
  color: white;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.5px;
  transition: 1s ease-in-out;
}

.link .appointment {
  padding: 12px 40px;
  border-radius: 2rem 0;
  border: 1px solid #fdae5c;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.5px;
  color: #fdae5c;
  transition: 1s ease-in-out;
}

/* Media Query */
@media screen and (width <=985px) {
  .home {
    padding-left: 3rem;
  }

  .container h1 {
    font-size: 3.5rem;
  }
}

@media screen and (width <=768px) {
  .home {
    padding-left: 2rem;
  }

  .container h1 {
    font-size: 3.2rem;
  }

  .container p {
    width: 400px;
    font-size: 15px;
  }

  .link .explore {
    padding: 10px 25px;
  }

  .link .contact {
    padding: 10px 25px;
  }
}

@media screen and (width <=480px) {
  .home {
    padding: 11rem 1rem 0 2rem;
    height: 80vh;
  }

  .home::before {
    bottom: 20%;
  }

  .container h1 {
    font-size: 2.5rem;
  }

  .container p {
    font-size: 14px;
    width: fit-content;
  }

  .link a {
    font-size: 14px;
  }
}

@media screen and (width <=380px) {
  .home {
    padding-top: 9rem;
  }

  .container h1 {
    font-size: 2.3rem;
  }

  .link .contact {
    padding: 10px 17px;
  }

  .link .explore {
    padding: 10px 17px;
    margin-right: 10px;
  }
}
