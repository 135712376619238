.blog {
  padding: 3% 20%;
  position: relative;
  margin-bottom: 5%;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
  color: #2c2a2f;
}

.blog h1 {
  font-size: 1.5rem;
  line-height: 2;
  letter-spacing: 1px;
}

.blog h2 {
  font-size: 20px;
  line-height: 1.3;
}

.blog p {
  font-size: 20px;
  font-family: Nunito, sans-serif;
  line-height: 1.3;
  margin-bottom: 1.2em;
}

.blog img {
  width: 100%;
  height: auto;
  margin-bottom: 1.2em;
}

.blog-container .nav-bg {
  top: 0;
  left: 0;
  height: 78px;
  background-color: #2c2a2f;
}

.blog i a{
  text-decoration: underline;
  
}

.comment-section {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.comment-input {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.comment-input img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 3.2rem;
  margin-right: 10px;
  border: 1px solid var(--color-secondary);
}


.comment-textarea {
  width: 400px;
  height: 100px;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  resize: none;
  border: 2px solid var(--color-secondary);
}

.post-button {
  width: 8rem;
  padding: 10px 20px;
  background-color: var(--color-primary);
  color: white;
  font-weight: bold;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 12rem;

}

.post-button:hover{
  background-color: var(--color-secondary);

}

.user-info img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 2rem;
  margin-right: 10px;
  border: 1px solid var(--color-secondary);
}

.comment {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.user-info .user-name{
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
}

.comment p{

  font-size: 1rem;
  letter-spacing: 1px;
}
.comment-text {
  max-width: 400px;
  margin-left: 4rem;
  margin-top: -2rem;
}

.comment-actions {
  margin-top: 5px;
  margin-left: 4rem;
  display: flex;
  gap: 5px;
}

@media screen and (max-width: 375px) {
  .blog {
    padding: 3% 5%;
  }
  .comment-input {
    flex-direction: column;
    align-items: flex-start;
  }
  .comment-textarea {
    width: 100%;
    margin-bottom: 10px;
  }
  .post-button {
    margin-left: 0;
  }
  .comment-text {
    margin-left: 0;
  }
  .comment-actions {
    margin-left: 0;
  }
}


@media screen and (max-width: 450px) {
  .blog {
    padding: 3% 5%;
  }
  .comment-input {
    flex-direction: column;
    align-items: flex-start;
  }
  .comment-textarea {
    width: 100%;
    margin-bottom: 10px;
  }
  .post-button {
    margin-left: 0;
  }

  .comment-text {
    margin-left: 0;
    margin-top: .4rem;
  }
  .comment-actions {
    margin-left: 0;
  }
}