.counter {
  padding: 2.6rem 3rem 4rem;
}

.counter-row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.counter-column {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

.counter-column .counter-number {
  font-weight: 600;
  font-size: 50px;
  font-family: Poppins, sans-serif;
  color: #fdae5c;
}

.counter-column span {
  display: block;
  font-size: 18px;
  color: rgb(0 0 0 / 70%);
  line-height: 1.2;
  padding-left: 15px;
  font-family: Poppins, sans-serif;
}

@media screen and (width <=1024px) {
  .counter-row {
    grid-template-columns: 1fr 1fr;
  }

  .counter-column {
    padding: 2rem 0;
  }
}

@media screen and (width <=560px) {
  .counter {
    padding: 1rem 1rem 2rem;
  }

  .counter-row {
    grid-template-columns: 1fr;
  }
}
