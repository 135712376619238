.work {
  padding-top: 4rem;
  padding-bottom: 6rem;
  margin-right: 3rem;
  margin-left: 3rem;
  position: relative;
  background-image: url("../../images/spacejoy-uGWNcejbf2E-unsplash.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.work::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -30px;
  overflow: visible;
  width: 50%;
  height: 70px;
  z-index: 1;
  transform: skewY(4deg);
  background-color: #fff;
}

.work::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -30px;
  overflow: visible;
  width: 50%;
  height: 70px;
  z-index: 1;
  transform: skewY(-4deg);
  background-color: #fff;
}

.overlay {
  position: absolute;
  width: 50%;
  inset: 0;
  content: "";
  opacity: 1;
  background: #000;
}

.work-heading {
  width: 50%;
  text-align: center;
  padding-bottom: 2rem;
}

.work-heading h2 {
  position: relative;
  font-size: 2.2rem;
  font-weight: 600;
  font-family: "Nunito Sans", sans-serif;
  padding-top: 2rem;
  color: rgb(0 0 0 / 80%);
  color: white;
}

.work-heading span {
  display: inline-block;
  margin-bottom: 0;
  color: #fdae5c;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: relative;
  font-family: Poppins, sans-serif;
}

.work-heading span::before {
  position: absolute;
  top: 50%;
  right: -60px;
  content: "";
  transform: translateY(-50%);
  width: 50px;
  height: 1px;
  background: #fdae5c;
}

.work-heading span::after {
  position: absolute;
  top: 50%;
  left: -60px;
  content: "";
  transform: translateY(-50%);
  width: 50px;
  height: 1px;
  background: #fdae5c;
}

.work-row {
  position: relative;
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.column1 {
  width: 50%;
}

.work-card {
  padding-bottom: 4rem;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 50%;
}

.work-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.number {
  width: 90px;
  height: 90px;
  background: #fdae5c;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number span {
  color: #fff;
  font-size: 30px;
}

.work-content h3 {
  padding-top: 1rem;
  color: white;
  padding-bottom: 1rem;
  font-size: 24px;
  font-weight: 500;
  font-family: "Nunito Sans", sans-serif;
}

.work-content p {
  font-size: 15px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  color: rgb(255 255 255 / 80%);
  line-height: 1.8;
}

@media screen and (width <=1024px) {
  .work-row {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .work-card {
    max-width: 100%;
  }
}

@media screen and (width <=768px) {
  .work {
    padding: 1rem 1rem 2rem;
    margin: 0;
    display: grid;
    place-items: center;
  }

  .overlay {
    width: 100%;
    opacity: 0.4;
  }

  .work-heading {
    width: 100%;
  }

  .work-heading h2 {
    font-size: 1.7rem;
  }

  .column1 {
    width: 100%;
  }

  .work-card {
    padding-left: 0;
    padding-right: 0;
    max-width: 100%;
  }
}
