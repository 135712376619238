.event-topbar {
  background-image: url("../../assets/eventbackground.jpg");
  object-fit: fill;
  width: 100%;
  height: 80vh;
  margin-bottom: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8rem 3rem;
}

.event-box-register {
  margin-top: 8rem;
  width: 700px;
  height: 400px;
  background-color: var(--color-text-light);
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.event-box-register h1 {
  font-size: 24px;
  margin-bottom: 10px;
  color: var(--color-text);
}

.event-box-register p {
  font-size: 14px;
  margin-bottom: 20px;
}

.event-box-register .input-container {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-bottom: 20px;
}

.event-box-register input {
  width: 120%;
  height: 45px;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
}

.event-box-register button.register-button {
  width: 150px;
  height: 40px;
  background-color: var(--color-primary);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.event-box-register button.register-button:hover {
  background-color: var(--color-secondary);
}

.event-box .event-box-content {
  color: #fff;
}

.event-box .event-box-content h3 {
  font-size: 1.4rem;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.event-box .event-box-content .event-text {
  font-size: 1.25rem;
  letter-spacing: 1px;
  width: 50%;
  margin-bottom: 10px;
}

.event-box-content-time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  margin-bottom: 10px;
}

.event-box-content-time h4 {
  font-size: 1.25rem;
  letter-spacing: 1px;
  font-weight: 100;
  margin-bottom: 7px;
}

.event-box-content-time p {
  letter-spacing: 1px;
  font-weight: 100;
}

.why-join-card {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}

.net-card {
  width: 400px;
  height: 200px;
  background-color: var(--color-text-light);

  /* box shadow */
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 40%);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-header h1 {
  font-size: 1.4rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--color-text);
  letter-spacing: 1px;
}

.card-icon {
  font-size: 3rem;
  color: var(--color-primary);
}

.net-card p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: var(--color-text);
  letter-spacing: 1px;
}

.event-why-join {
  display: flex;
  padding: 2rem 4rem;
}

.event-why-join .event-why-join-content h1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: var(--color-text);
}

.event-why-join .event-why-join-content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  letter-spacing: 1px;
  color: var(--color-text);
  width: 70%;
}

.event-why-join .event-why-join-content button {
  width: 150px;
  height: 40px;
  background-color: var(--color-primary);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.event-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}

.half {
  width: 100%;
  height: 50%;
}

.red {
  background-color: red;
}

.blue {
  background-color: blue;
}

.number-event {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.event-speakers .event-speakers-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.event-speakers .event-speakers-header h1 {
  color: var(--color-primary);
  font-size: 1.8rem;
  letter-spacing: 1px;
}

.event-speakers .event-speakers-header p {
  color: var(--color-text);
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-weight: lighter;
}

.event-speakers-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  padding: 2rem 5rem;
  margin-left: 7rem;
}

.event-speakers-card-image {
  width: 100%;
  height: 200px;
  background-color: #f5f5f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 16px;
}

.event-speakers-card-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.event-speakers-card {
  width: 250px;
  margin: 10px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.event-upcoming-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.event-upcoming-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 20px;
  padding: 2rem 5rem;
}

.event-upcoming-card {
  margin: 10px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: var(--color-text-light);
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);
}

.event-upcoming-card-image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.event-upcoming-card-image img {
  height: 250px;
  width: 350px;
  vertical-align: middle;
  transition: 0.3s;
}

.event-upcoming-card-content h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.event-upcoming-card-content p {
  font-size: 14px;
  color: var(--color-text);
  width: 100%;
  margin-bottom: 10px;
}

.event-upcoming .event-upcoming-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.event-upcoming .event-upcoming-header p {
  color: var(--color-text);
  font-size: 1.2rem;
  letter-spacing: 1px;
  font-weight: lighter;
  margin-bottom: 8px;
}

.event-upcoming .event-upcoming-header h1 {
  color: var(--color-primary);
  font-size: 1.8rem;
  letter-spacing: 1px;
}

.event-upcoming-card-button {
  display: flex;
  justify-content: flex-start;
}

.event-upcoming-card-button .event-upcoming-card-button-button {
  width: 150px;
  height: 40px;
  background-color: var(--color-primary);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.event-upcoming-card-button .event-upcoming-card-button-button:hover {
  background-color: var(--color-secondary);
}

.upcoming-purchase {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.purchase-price {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  color: var(--color-text);
  margin-top: 0.4rem;
  width: 150px;
  height: 40px;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
}

.tickets-btn {
  margin-top: 20px;
}

.full-width-button {
  width: 340px;
  height: 50px;
  background-color: var(--color-text-light);
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

.button-section {
  display: flex;
  align-items: center;
}

.button-divider {
  width: 1px;
  height: 30px;
  background-color: var(--color-primary);
}

.ticket-icon,
.location-icon {
  font-size: 20px;
  margin-right: 5px;
}

.ticket-count,
.location {
  font-size: 14px;
}

.ticket-count {
  margin-right: 10px;
}

/* ====== Media queries ====== */








@media screen and (max-width: 1024px) and (min-height: 1180px) {
  .event-topbar {
    object-fit: fill;
    width: 146%;
    /* padding: 6rem 2rem; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;
    height: 60vh;
  }

  .event-box-content-time{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }

 
  .event-box-register {
    width: 70%;
    height: auto;
    padding: 1.5rem;
    border: 1px solid var(--color-primary);

  }

  .event-box-register h1 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .event-box-register p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  input {
    width: 120%;
    height: 45px;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
  }
  

  .event-box-register button.register-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .event-box .event-box-content .event-text {
    width: 100%;
  }

  .net-card {
    width: 180%;
    height: 16rem;
    
  }

  .net-card p {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .event-why-join {
    padding: 1rem 2rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 15rem;
  }

  .event-why-join .event-why-join-content h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .event-why-join .event-why-join-content p {
    font-size: 24px;
    margin-bottom: 16px;
    width: 200%;
  }

  .event-why-join .event-why-join-content button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .why-join-card{
    /* margin-top: 1rem; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12rem;
    
  }
  .event-speakers-card-content{
    font-size: 1.3rem;
  }

  .event-speakers-content {
    padding: 1rem 2rem;
    margin-left: 9rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
  }
  .event-speakers-header{
    margin-left: 26rem;
  }

  
  .event-upcoming-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 19rem;
  }

  .event-upcoming-card {
    width: 250%;
    margin-left: -3rem;
    
  }

  .event-upcoming-card-image img {
    height: 350px;
    width: 100%;
  }
.event-upcoming-header{
  margin-left: 26rem;
}

  .event-upcoming-card-content h3 {
    font-size: 26px;
  }

  .event-upcoming-card-content p {
    font-size: 20px;
  }

  .event-upcoming .event-upcoming-header p {
    font-size: 1.3rem;
  }

  .event-upcoming .event-upcoming-header h1 {
    font-size: 2.4rem;
  }

  .event-upcoming-card-button .event-upcoming-card-button-button {
    width: 150px;
    height: 52px;
    font-size: 24px;
    margin-top: 8px;
  }

  .upcoming-purchase {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .purchase-price {
    width: 140px;
    height: 42px;
    font-size: 20px;
  }

  .full-width-button {
    width: 120%;
  }

  .ticket-icon,
  .location-icon {
    font-size: 20px;
    margin-right: 3px;
  }

  .ticket-count,
  .location {
    font-size: 18px;
  }

  .ticket-count {
    margin-right: 5px;
  }
}

@media screen and (max-width: 820px) and (min-height: 1180px){
  .event-upcoming-card {
    width: 250%;
    margin-left: -2rem;
    
  }
  .event-upcoming-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 22rem;
  }


}

@media screen and (max-width: 768px) and (min-height: 1024px) {
  .event-topbar {
    object-fit: fill;
    width: 152%;
    /* padding: 6rem 2rem; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;
    height: 60vh;
  }

  .event-box-content-time{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }

 
  .event-box-register {
    width: 70%;
    height: auto;
    padding: 1.5rem;
    border: 1px solid var(--color-primary);

  }

  .event-box-register h1 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .event-box-register p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  input {
    width: 120%;
    height: 45px;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
  }
  

  .event-box-register button.register-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .event-box .event-box-content .event-text {
    width: 100%;
  }

  .net-card {
    width: 180%;
    height: 16rem;
    
  }

  .net-card p {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .event-why-join {
    padding: 1rem 2rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12rem;
  }

  .event-why-join .event-why-join-content h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .event-why-join .event-why-join-content p {
    font-size: 24px;
    margin-bottom: 16px;
    width: 200%;
  }

  .event-why-join .event-why-join-content button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .why-join-card{
    /* margin-top: 1rem; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12rem;
    
  }
  .event-speakers-card-content{
    font-size: 1.3rem;
  }

  .event-speakers-content {
    padding: 1rem 2rem;
    margin-left: 9rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
  }
  .event-speakers-header{
    margin-left: 26rem;
  }

  
  .event-upcoming-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 22rem;
  }

  .event-upcoming-card {
    width: 250%;
    margin-left: -2rem;
    
  }

  .event-upcoming-card-image img {
    height: 350px;
    width: 100%;
  }
.event-upcoming-header{
  margin-left: 26rem;
}

  .event-upcoming-card-content h3 {
    font-size: 26px;
  }

  .event-upcoming-card-content p {
    font-size: 20px;
  }

  .event-upcoming .event-upcoming-header p {
    font-size: 1.3rem;
  }

  .event-upcoming .event-upcoming-header h1 {
    font-size: 2.4rem;
  }

  .event-upcoming-card-button .event-upcoming-card-button-button {
    width: 150px;
    height: 52px;
    font-size: 24px;
    margin-top: 8px;
  }

  .upcoming-purchase {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .purchase-price {
    width: 140px;
    height: 42px;
    font-size: 20px;
  }

  .full-width-button {
    width: 120%;
  }

  .ticket-icon,
  .location-icon {
    font-size: 20px;
    margin-right: 3px;
  }

  .ticket-count,
  .location {
    font-size: 18px;
  }

  .ticket-count {
    margin-right: 5px;
  }
}


@media screen and (max-width: 912px) and (min-height: 1368px) {
  .event-topbar {
    object-fit: fill;
    width: 145%;
    /* padding: 6rem 2rem; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 5rem;
    height: 60vh;
  }

  .event-box-content-time{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
  }

 
  .event-box-register {
    width: 70%;
    height: auto;
    padding: 1.5rem;
    border: 1px solid var(--color-primary);

  }

  .event-box-register h1 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .event-box-register p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  input {
    width: 120%;
    height: 45px;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 5px;
  }
  

  .event-box-register button.register-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .event-box .event-box-content .event-text {
    width: 100%;
  }

  .net-card {
    width: 200%;
    height: 16rem;
    
  }

  .net-card p {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .event-why-join {
    padding: 1rem 2rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12rem;
  }

  .event-why-join .event-why-join-content h1 {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .event-why-join .event-why-join-content p {
    font-size: 24px;
    margin-bottom: 16px;
    width: 200%;
  }

  .event-why-join .event-why-join-content button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .why-join-card{
    /* margin-top: 1rem; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 18rem;
    
  }
  .event-speakers-card-content{
    font-size: 1.3rem;
  }

  .event-speakers-content {
    padding: 1rem 2rem;
    margin-left: 9rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
  }
  .event-speakers-header{
    margin-left: 26rem;
  }

  
  .event-upcoming-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 22rem;
  }

  .event-upcoming-card {
    width: 250%;
    margin-left: -1.4rem;

    
  }

}




@media screen and (max-width: 450px) and (min-height: 896px) {
  .event-topbar {
    padding: 6rem 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 5rem;
    height: 110vh;
  }

  .event-box-content-time{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
  }

 
  .event-box-register {
    width: 100%;
    height: auto;
    padding: 1.5rem;
    border: 1px solid var(--color-primary);

  }

  .event-box-register h1 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .event-box-register p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  input {
    width: 100%;
  }

  .event-box-register button.register-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .event-box .event-box-content .event-text {
    width: 100%;
  }

  .net-card {
    width: 100%;
    height: auto;
  }

  .net-card p {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .event-why-join {
    padding: 1rem 2rem;
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-why-join .event-why-join-content h1 {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .event-why-join .event-why-join-content p {
    font-size: 12px;
    margin-bottom: 16px;
    width: 100%;
  }

  .event-why-join .event-why-join-content button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .why-join-card{
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-speakers-content {
    padding: 1rem 2rem;
    margin-left: 3rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
  }

  
  .event-upcoming-content{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-upcoming-card {
    width: 150%;
    margin-left: -4.1rem;
    
  }

  .event-upcoming-card-image img {
    height: 300px;
    width: 100%;
  }

  .event-upcoming-card-content h3 {
    font-size: 16px;
  }

  .event-upcoming-card-content p {
    font-size: 12px;
  }

  .event-upcoming .event-upcoming-header h1 {
    font-size: 16px;
  }

  .event-upcoming-card-button .event-upcoming-card-button-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
    margin-top: 8px;
  }

  .upcoming-purchase {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .purchase-price {
    width: 120px;
    height: 32px;
    font-size: 12px;
  }

  .full-width-button {
    width: 100%;
  }

  .ticket-icon,
  .location-icon {
    font-size: 16px;
    margin-right: 3px;
  }

  .ticket-count,
  .location {
    font-size: 12px;
  }

  .ticket-count {
    margin-right: 5px;
  }
}




@media screen and (max-width: 400px) {
  .event-topbar {
    padding: 6rem 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 5rem;
    height: 120vh;
  }

  .event-box-content-time{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

 
  .event-box-register {
    width: 100%;
    height: auto;
    padding: 1.5rem;
    border: 1px solid var(--color-primary);

  }

  .event-box-register h1 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .event-box-register p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  input {
    width: 100%;
  }

  .event-box-register button.register-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .event-box .event-box-content .event-text {
    width: 100%;
  }

  .net-card {
    width: 100%;
    height: auto;
  }

  .net-card p {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .event-why-join {
    padding: 1rem 2rem;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-why-join .event-why-join-content h1 {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .event-why-join .event-why-join-content p {
    font-size: 12px;
    margin-bottom: 16px;
    width: 100%;
  }

  .event-why-join .event-why-join-content button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .why-join-card{
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-speakers-content {
    padding: 1rem 2rem;
    margin-left: 3rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
  }

  
  .event-upcoming-content{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-upcoming-card {
    width: 150%;
    margin-left: -4rem;
    
  }

  .event-upcoming-card-image img {
    height: 200px;
    width: 100%;
  }

  .event-upcoming-card-content h3 {
    font-size: 16px;
  }

  .event-upcoming-card-content p {
    font-size: 12px;
  }

  .event-upcoming .event-upcoming-header h1 {
    font-size: 16px;
  }

  .event-upcoming-card-button .event-upcoming-card-button-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
    margin-top: 8px;
  }

  .upcoming-purchase {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .purchase-price {
    width: 120px;
    height: 32px;
    font-size: 12px;
  }

  .full-width-button {
    width: 100%;
  }

  .ticket-icon,
  .location-icon {
    font-size: 16px;
    margin-right: 3px;
  }

  .ticket-count,
  .location {
    font-size: 12px;
  }

  .ticket-count {
    margin-right: 5px;
  }
}




@media screen and (max-width: 375px) and (min-height: 667px) {
  .event-topbar {
    padding: 6rem 2rem;
    border: 1px solid red;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 5rem;
    height: 150vh;
  }

  .event-box-content-time{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

 
  .event-box-register {
    width: 100%;
    height: auto;
    padding: 1.5rem;
    border: 1px solid var(--color-primary);

  }

  .event-box-register h1 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .event-box-register p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  input {
    width: 100%;
  }

  .event-box-register button.register-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .event-box .event-box-content .event-text {
    width: 100%;
  }

  .net-card {
    width: 100%;
    height: auto;
  }

  .net-card p {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .event-why-join {
    padding: 1rem 2rem;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-why-join .event-why-join-content h1 {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .event-why-join .event-why-join-content p {
    font-size: 12px;
    margin-bottom: 16px;
    width: 100%;
  }

  .event-why-join .event-why-join-content button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .why-join-card{
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-speakers-content {
    padding: 1rem 2rem;
    margin-left: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
  }

  
  .event-upcoming-content{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-upcoming-card {
    width: 150%;
    margin-left: -3.8rem;
    
  }

  .event-upcoming-card-image img {
    height: 200px;
    width: 100%;
  }

  .event-upcoming-card-content h3 {
    font-size: 16px;
  }

  .event-upcoming-card-content p {
    font-size: 12px;
  }

  .event-upcoming .event-upcoming-header h1 {
    font-size: 16px;
  }

  .event-upcoming-card-button .event-upcoming-card-button-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
    margin-top: 8px;
  }

  .upcoming-purchase {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .purchase-price {
    width: 120px;
    height: 32px;
    font-size: 12px;
  }

  .full-width-button {
    width: 100%;
  }

  .ticket-icon,
  .location-icon {
    font-size: 16px;
    margin-right: 3px;
  }

  .ticket-count,
  .location {
    font-size: 12px;
  }

  .ticket-count {
    margin-right: 5px;
  }
}





@media screen and (max-width: 280px) and (min-height: 653px) {
  .event-topbar {
    padding: 6rem 2rem;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 5rem;
    height: 170vh;
  }

  .event-box-content-time{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

 
  .event-box-register {
    width: 100%;
    height: auto;
    padding: 1.5rem;
    border: 1px solid var(--color-primary);

  }

  .event-box-register h1 {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .event-box-register p {
    font-size: 12px;
    margin-bottom: 16px;
  }

  .input-container input {
    width: 100%;
  }

  .event-box-register button.register-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .event-box .event-box-content .event-text {
    width: 100%;
  }

  .net-card {
    width: 100%;
    height: auto;
  }

  .net-card p {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .event-why-join {
    padding: 1rem 2rem;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-why-join .event-why-join-content h1 {
    font-size: 16px;
    margin-bottom: 16px;
  }

  .event-why-join .event-why-join-content p {
    font-size: 12px;
    margin-bottom: 16px;
    width: 100%;
  }

  .event-why-join .event-why-join-content button {
    width: 120px;
    height: 32px;
    font-size: 14px;
  }

  .why-join-card{
    margin-top: 4rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .event-speakers-content {
    padding: 1rem 2rem;
    margin-left: -2rem;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
  }

  
  .event-upcoming-content{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  

  }

  .event-upcoming-card {
    width: 200%;
    margin-left: -4.1rem;
    
  }

  .event-upcoming-card-image img {
    height: 200px;
    width: 100%;
  }

  .event-upcoming-card-content h3 {
    font-size: 16px;
  }

  .event-upcoming-card-content p {
    font-size: 12px;
  }

  .event-upcoming .event-upcoming-header h1 {
    font-size: 16px;
  }

  .event-upcoming-card-button .event-upcoming-card-button-button {
    width: 120px;
    height: 32px;
    font-size: 14px;
    margin-top: 8px;
  }

  .upcoming-purchase {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .purchase-price {
    width: 120px;
    height: 32px;
    font-size: 12px;
  }

  .full-width-button {
    width: 100%;
  }

  .ticket-icon,
  .location-icon {
    font-size: 16px;
    margin-right: 3px;
  }

  .ticket-count,
  .location {
    font-size: 12px;
  }

  .ticket-count {
    margin-right: 5px;
  }
}


