body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-background-color: #fff;
  --color-primary: #fdae5c;
  --color-secondary: #f6d6ad;
  --color-text: #1c1c1c;
  --color-text-light: #fff;
  --color-text-dark: #edc8a1;
  --container-width-lg: 75%;
  --container-width-md: 65%;
  --container-width-sm: 90%;
  --transition: all 400ms ease;
  --border-radius: 0.5rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  border: 0;
  outline: 0;
  text-decoration: none;
  font-family: Nunito, sans-serif;
}

html {
  scroll-behavior: smooth;
}
