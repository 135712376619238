.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40rem;
  background-color: white;
  border: 1px solid var(--color-primary);
  border-radius: 1rem;
  box-shadow: 24px;
  padding: 8px 16px 12px;
}

.appointment {
  position: relative;
}

.appointment span {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.8rem;
  cursor: pointer;
  color: var(--color-primary);
}

.appointment-title h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-primary);
  margin-bottom: 1rem;
}

.book-session-dropdown {
  display: flex;
  gap: 3.8rem;
  flex-wrap: wrap;
}

.app-appointment-date-picker .app-date-picker-container {
  margin-left: 0.5rem;
  display: flex;
  gap: 2.6rem;
}

.custom-date-picker {
  width: 87%;
}

.book-session-textarea {
  padding: 0.6rem;
}

.book-session-textarea .apm-message-control {
  min-width: 93.4%;
  max-width: 100%;
  padding: 5px 10px;
  background: transparent;
  border-radius: 5px;
  border: none;
  font-size: 1.4rem;
  box-shadow: 1px 1px 5px #ce8c1b;
}

.book-session-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem;
}

.book-session-name-phone {
  margin: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 4.8rem;
}

.apm-book-session-button,
.cancel-btn {
  background-color: var(--color-primary);
  color: var(--color-text-light);
  border-radius: 10px;
  font-size: 1.2rem;
  padding: 8px 13px;
}
