/* stylelint-disable selector-class-pattern */
.app-date-search-container {
  padding: 1rem 6rem;
}

.app-DateSearch-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 2rem;
}

.app-DateSearch-header h1 {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 0.7rem;
  color: var(--color-primary);
}

.app-DateSearch-header p {
  font-size: 1.3rem;
  font-weight: 400;
  color: var(--color-text);
}

.app-date-search-content-date {
  margin-top: 2rem;
}

.app-date-search-content-search {
  margin-top: 2rem;
}

.app-DateSearch-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-primary);
  border-radius: var(--border-radius);
  padding: 1rem 5rem;
}

/* ===== Media queries */

@media screen and (width <= 768px) {
  .app-date-search-container {
    padding: 1rem 3rem;
  }

  .app-date-search-content {
    padding: 1rem 2rem;
  }
}
