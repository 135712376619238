.contact-banner{   
  position: absolute; 
  top: 0;left: 0;
  width: 100%;
  height: 100%;
  background: url('../../images/contactUs.webp') ;   
  background-size: cover;
  background-position: center;    
  z-index: -1;
  display: grid; 
  place-items: center;
}

.contact-section{ 
  margin: 120px auto 200px;
  width: 90%;
  top: 200px; 
  max-width: 900px;
  padding: 30px;
  background-color: rgb(255 255 255);
  color: var(--color-text);
}


.contact-number .contact-icon{
  font-size: 3rem;
  color: #ce8c1b;
  margin-right: 10px;
}

.contact-number .contact-icon .telephone-icon{
  background-color: #D9D9D9;
  border-radius: 50%;
  padding: .8rem .2rem;

}

.contact-title{
  color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-number {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  width: 20rem;
  border-radius: .5rem;
  box-shadow: 1px 1px 1px 2px rgb(0 0 0 / 20%)
}

.app-contact{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem 1rem;
}


.contact-Paged{
  display: flex;
  gap: 2rem; 
}

.contact-form{
  padding: .7rem 0;
}

.contact-form h3{
  color: var(--color-primary);
  padding: 1rem 0;
  letter-spacing: 2px;
}

.contact-form p{
  letter-spacing: 2px;
}

.contact-name{
  padding: 2rem 0;
}

.contact-name-content{
  display: flex;
  gap: 1rem;
    
}

.contact-name-subject{
  display: flex;
  gap: 1rem;
  padding: 1rem 0;

}

.contact-message-input {
  padding: .2rem 0;
}

.contact-message-input .apm-message-control {
  min-width: 100%;
  max-width: 100%;
  padding: 5px 10px;
  background: transparent;
  border-radius: 5px;
  border: none;
  font-size: 1.4rem;
  box-shadow: 1px 1px 5px var(--color-primary);
}

.contact-submit button{
  background-color: var(--color-primary);
  border: none;
  border-radius: 5px;
  padding: 10px 50px;
  font-size: 17px;
  color: white;
  display: block;
  margin: 20px auto;
  transition: .3s;
  cursor: pointer;
}


/* ===== Media queries ==== */

@media screen and (width <= 768px){
  .contact-section{
    margin: 120px auto 200px;
    width: 90%;
    top: 200px; 
    max-width: 900px;
    padding: 30px;
    background-color: rgb(255 255 255);
    color: var(--color-text);
  }

  .contact-number{
    width: 100%;
  }

  .contact-number .contact-icon{
    font-size: 2rem;
    color: #ce8c1b;
    margin-right: 10px;
  }

  .contact-number .contact-icon .telephone-icon{
    background-color: #D9D9D9;
    border-radius: 50%;
    padding: .8rem .2rem;
  }

  .contact-title{
    color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-contact{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 1rem;
  }

  .contact-Paged{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
  }

  .contact-form{
    padding: .7rem 0;
  }

  .contact-form h3{
    color: var(--color-primary);
    padding: 1rem 0;
    letter-spacing: 2px;
  }

  .contact-form p{
    letter-spacing: 2px;
  }

  .contact-name{
    padding: 2rem 0;
  }

  .contact-name-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
  }

  .contact-name-subject{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;

  }

  .contact-message-input {
    padding: .2rem 0;
  }

  .contact-message-input .apm-message-control {
    min-width: 100%;
    max-width: 100%;
    padding: 5px 10px;
    background: transparent;
    border-radius: 5px;
    border: none;
    font-size: 1.4rem;
    box-shadow: 1px 1px 5px var(--color-primary);
  }

  .contact-submit button{
    background-color: var(--color-primary);
    border: none;
    border-radius: 5px;
    padding: 10px 50px;
    font-size: 17px;
    color: white;
    display: block;
    margin: 20px auto;
    transition: .3s;
    cursor: pointer;
  }

}

@media screen and (width <= 500px){ 
  .contact-section{
    margin: 120px auto 200px;
    width: 90%;
    top: 200px; 
    max-width: 900px;
    padding: 30px;
    background-color: rgb(255 255 255);
    color: var(--color-text);
  }

  .contact-number{
    width: 100%;
  }

  .contact-number .contact-icon{
    font-size: 2rem;
    color: #ce8c1b;
    margin-right: 10px;
  }

  .contact-number .contact-icon .telephone-icon{
    background-color: #D9D9D9;
    border-radius: 50%;
    padding: .8rem .2rem;
  }

  .contact-title{
    color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-contact{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 1rem;
  }

  .contact-Paged{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
  }

  .contact-form{
    padding: .7rem 0;
  }

  .contact-form h3{
    color: var(--color-primary);
    padding: 1rem 0;
    letter-spacing: 2px;
  }

  .contact-form p{
    letter-spacing: 2px;
  }

  .contact-name{
    padding: 2rem 0;
  }

  .contact-name-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
  }

  .contact-name-subject{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;

  }

  .contact-message-input {
    padding: .2rem 0;
  }

  .contact-message-input .apm-message-control {
    min-width: 100%;
    max-width: 100%;
    padding: 5px 10px;
    background: transparent;
    border-radius: 5px;
    border: none;
    font-size: 1.4rem;
    box-shadow: 1px 1px 5px var(--color-primary);

  }

  .contact-submit button{
    background-color: var(--color-primary);
    border: none;
    border-radius: 5px;
    padding: 10px 50px;
    font-size: 17px;
    color: white;
    display: block;
    margin: 20px auto;
    transition: .3s;
    cursor: pointer;
  }

}

@media screen and (width <= 400px){
  .contact-section{
    margin: 120px auto 200px;
    width: 90%;
    top: 200px; 
    max-width: 900px;
    padding: 30px;
    background-color: rgb(255 255 255);
    color: var(--color-text);
  }

  .contact-number{
    width: 100%;
  }

  .contact-number .contact-icon{
    font-size: 2rem;
    color: #ce8c1b;

    /* background-color: #D9D9D9;
    border-radius: 30%; */
    margin-right: 10px;
  }

  .contact-number .contact-icon .telephone-icon{
    background-color: #D9D9D9;
    border-radius: 50%;
    padding: .8rem .2rem;
  }

  .contact-title{
    color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-contact{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 1rem;
  }

  .contact-Paged{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
  }

  .contact-form{
    padding: .7rem 0;
  }

  .contact-form h3{
    color: var(--color-primary);
    padding: 1rem 0;
    letter-spacing: 2px;
  }

  .contact-form p{
    letter-spacing: 2px;
  }

  .contact-name{
    padding: 2rem 0;
  }

  .contact-name-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
  }

  .contact-name-subject{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;

  }

  .contact-message-input {
    padding: .2rem 0;
  }

  .contact-message-input .apm-message-control {
    min-width: 100%;
    max-width: 100%;
    padding: 5px 10px;
    background: transparent;
    border-radius: 5px;
    border: none;
    font-size: 1.4rem;
    box-shadow: 1px 1px 5px var(--color-primary);

  }

  .contact-submit button{
    background-color: var(--color-primary);
    border: none;
    border-radius: 5px;
    padding: 10px 50px;
    font-size: 17px;
    color: white;
    display: block;
    margin: 20px auto;
    transition: .3s;
    cursor: pointer;
  }

}

@media screen and (width <= 320px){
  .contact-section{
    margin: 120px auto 200px;
    width: 90%;
    top: 200px; 
    max-width: 900px;
    padding: 30px;
    background-color: rgb(255 255 255);
    color: var(--color-text);
  }

  .contact-number{
    width: 100%;
  }

  .contact-number .contact-icon{
    font-size: 2rem;
    color: #ce8c1b;

    /* background-color: #D9D9D9;
    border-radius: 30%; */
    margin-right: 10px;
  }

  .contact-number .contact-icon .telephone-icon{
    background-color: #D9D9D9;
    border-radius: 50%;
    padding: .8rem .2rem;
  }

  .contact-title{
    color: var(--color-primary);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app-contact{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 1rem;
  }

  .contact-Paged{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
  }

  .contact-form{
    padding: .7rem 0;
  }

  .contact-form h3{
    color: var(--color-primary);
    padding: 1rem 0;
    letter-spacing: 2px;
  }

  .contact-form p{
    letter-spacing: 2px;
  }

  .contact-name{
    padding: 2rem 0;
  }

  .contact-name-content{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
  }

  .contact-name-subject{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;

  }

  .contact-message-input {
    padding: .2rem 0;
  }

  .contact-message-input .apm-message-control {
    min-width: 100%;
    max-width: 100%;
    padding: 5px 10px;
    background: transparent;
    border-radius: 5px;
    border: none;
    font-size: 1.4rem;
    box-shadow: 1px 1px 5px var(--color-primary);

  }

}

