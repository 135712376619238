nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5rem;
}

#logo-btn {
  background-color: transparent;
  cursor: pointer;
}

#logo-btn img {
  width: 150px;
  height: 50px;
}

nav h1 {
  color: white;
  font-size: 2rem;
  font-family: Merriweather, serif;
  letter-spacing: 0.5px;
}

nav h1 span {
  color: #fdae5c;
}

.menu {
  display: flex;
}

.menu ul {
  display: flex;
}

.menu ul li {
  padding: 10px 25px;
  border-radius: 30px;
  transition: 0.3s;
  box-sizing: border-box;
  cursor: pointer;
}

.menu ul li:hover {
  box-shadow: inset 1px 1px 3px #fdae5c, 1px 1px 5px #fdae5c;
}

.menu ul li button {
  background-color: transparent;
  color: white;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.5px;
  display: block;
}

.menu ul li:hover button {
  color: #fdae5c;
}

.btn {
  background: #fdae5c;
  padding: 8px 20px;
  border: 2px solid #fdae5c;
  margin-left: 2rem;
  border-radius: 30px;
}

.btn a {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: Poppins, sans-serif;
  letter-spacing: 0.5px;
}

/* CSS For Transition When Scroll */
.navbar.active {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  z-index: 9;
  box-shadow: 0 3px 16px 0 rgb(0 0 0 / 10%);
  background-color: #2c2a2f;
  transform: translateY(70px);
  transition: transform 1s ease;
}

.nav-profile-icon {
  font-size: 25px;
  color: #fdae5c;
  border-radius: 50px;
  padding: 7px 10px;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  justify-content: center;
  align-items: center;
  gap: 10px;
  box-shadow: inset 1px 1px 2px #fdae5c, 1px 1px 5px #fdae5c;
}

.nav-profile-icon .fa-bars,
.nav-profile-icon .fa-times {
  font-size: 17px;
}

.nav-profile-icon:hover {
  color: white;
  background-color: #fdae5c;
}

.nav-profile-icon img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

/* Media Query for desktop mode Responsive Navbar */
@media screen and (width >=985px) {
  .menu .auth-nav {
    position: absolute;
    top: 55px;
    right: 6%;
    background-color: #2c2a2f;
    width: 250px;
    border-radius: 1rem;
    display: none;
  }

  .menu .auth-nav li {
    box-shadow: none !important;
  }

  .mobile-menu .auth-nav {
    display: block;
  }
}

/* Media Query Responsive Navbar */
@media screen and (width <=985px) {
  .navbar.active {
    display: none;
  }

  .menu .auth-nav {
    border-bottom: 1px solid #fff;
  }

  nav {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  nav h1 {
    font-size: 1.6rem;
  }

  .btn {
    margin: 0;
  }

  .menu {
    display: block;
    z-index: 9999 !important;
    position: absolute;
    left: 3%;
    top: 68px;
    width: 93%;
    background-color: #2c2a2f;
    transform: translateY(-300px);
    transition: transform 1s ease-in-out visibility 1s;

    /* padding-bottom: 1rem; */
    border-radius: 1rem;
    visibility: hidden;
  }

  .mobile-menu {
    visibility: visible;
    transform: translateY(0);
    transition: transform 1s ease -in-out, visibility 1s;
  }

  .menu ul {
    display: block;
  }

  .menu ul li {
    padding: 17px 3rem;
  }

  .btn {
    padding-left: 3rem;
    border: none;
    background: transparent;
  }

  .menu ul li:hover {
    box-shadow: none !important;
  }
}

@media screen and (width <=550px) {
  nav {
    padding-left: 2rem;
    padding-right: 2em;
  }
}

@media screen and (width <=380px) {
  nav {
    padding-left: 1rem;
    padding-right: 1em;
  }

  nav h1 {
    font-size: 1.5rem;
  }
}
