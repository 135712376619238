.doctors {
  padding: 1rem;
  max-width: 20rem;
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  background-color: var(--color-background-color);
  margin-bottom: 3rem;
}

.app-homepage-doctors-header {
  position: relative;
}

.app-homepage-content {
  position: relative;
}

.app-homepage-content .nav-bg {
  top: 0;
  left: 0;
  height: 78px;
  background-color: #2c2a2f;
}

.app-homepage-doctors-header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  color: var(--color-primary);
}

.swiper-container {
  width: 60%;
}

.carded {
  text-align: center;
  padding: 3rem 1rem;
}

.image {
  border-radius: 50%;
  border: 1px solid var(--color-primary);
  width: 10rem;
  height: 10rem;
}

.doctors-buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 1rem;
  gap: 0.6rem;
}

.doctors-buttons button {
  font-size: 1rem;
  padding: 0.6rem 0.9rem;
  cursor: pointer;
  z-index: 100;
  pointer-events: auto;
}

.doctors-buttons button:hover {
  background-color: var(--color-primary);
  color: var(--color-text-light);
  border-radius: 10px;
}

/* ===== Media queries ===== */

@media (width <= 1024px) {
  .doctors {
    max-width: 100%;
  }

  .swiper-container {
    width: 100%;
  }

  .carded {
    max-width: 100%;
  }

  .doctors-image {
    display: flex;
    justify-content: center;
  }

  .image {
    border-radius: 60px;
    border: 1px solid var(--color-primary);
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1rem;
    gap: 0.4rem;
  }

  .doctors-text h3,
  h4 {
    margin-left: -9rem;
  }

  .doctors-text p {
    margin-left: 6rem;
    width: 30%;
  }

  .doctors-buttons button {
    width: 4rem;
    height: 2rem;
  }

  .doctors-buttons .profile-button {
    font-size: 1rem;
    font-weight: 700;
  }

  .doctors-buttons .book-button {
    font-size: 1rem;
    font-weight: 700;
  }
}

@media (width <= 768px) {
  .doctors {
    max-width: 100%;
  }

  .swiper-container {
    width: 50%;
    height: 23rem;
  }

  .carded {
    max-width: 100%;
    height: 24rem;
  }

  .doctors-image {
    display: flex;
    justify-content: center;
  }

  .doctors-text {
    width: 150%;
    margin-left: -1rem;
  }

  .image {
    border-radius: 60px;
    border: 1px solid var(--color-primary);
    width: 8rem;
    height: 8rem;
  }

  .doctors-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 0.2rem;
  }

  .doctors-buttons button {
    font-size: 1rem;
    padding: 0;
  }
}

@media (width <= 425px) {
  .doctors {
    width: 100rem;
    text-align: center;
  }

  .swiper-container {
    width: 100%;
  }

  .carded {
    max-width: 100%;
    text-align: center;
  }

  .doctors-image {
    display: flex;
    justify-content: center;
  }

  .image {
    border-radius: 60px;
    border: 1px solid var(--color-primary);
    width: 8rem;
    height: 8rem;
  }

  .doctors-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 0.4rem;
  }

  .doctors-buttons button {
    font-size: 1.3rem;
    font-weight: 700;
  }
}

@media (width <= 375px) {
  .doctors {
    max-width: 100%;
  }

  .swiper-container {
    width: 100%;
  }

  .carded {
    max-width: 100%;
  }

  .doctors-image {
    display: flex;
    justify-content: center;
  }

  .image {
    border-radius: 60px;
    border: 1px solid var(--color-primary);
    width: 8rem;
    height: 8rem;
  }

  .doctors-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    gap: 0.4rem;
  }

  .doctors-buttons button {
    font-size: 1.3rem;
    font-weight: 700;
  }
}
