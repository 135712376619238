
.app-profile-content-header-left {
  position: relative;
  background-color: cadetblue;
  width: 100%;
  min-height: 25vh;
  padding-top: 5%;
}

.app-profile-content-header-left img {
  position: absolute;
  border: 8px solid cadetblue;
  bottom: -70px;
  left: 8vw;
  background-color: white;
  aspect-ratio: 1/1;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}

.app-profile-content-header-left-name {
  font-size: 1.5rem;
  padding-top: 5%;
  padding-left: 5%;
}

.app-profile-content-header-left-name h3 {
  font-weight: 300;
  color: var(--color-primary);
}

.app-profile-content-header-left-name p {
  font-weight: 300;
}

.doctor-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.app-profile-content-header-right {
  position: relative;
  width: 50%;
  min-height: 25vh;
  padding: 5%;
}

.app-profile-content-header-left-btn {
  padding: 0 5%;
}

.doctor-address {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.app-profile-content-header-left-btn button {
  margin: 1rem 0;
  border-radius: 8px;
  font-size: 1.3rem;
  padding: 1rem;
  background-color: var(--color-primary);
  color: var(--color-text-light);
  letter-spacing: 1px;
  cursor: pointer;
  transition: all 400ms ease;
}

.app-profile-content-header-left-btn button:hover {
  background-color: #f79834;
}

.app-profile-content-header-right-profile
  .app-profile-content-header-right-profile-location
  p {
  font-size: 1.5rem;
  font-weight: lighter;
  color: var(--color-text);
  margin-top: 1rem;
  margin-bottom: 1rem;
  letter-spacing: 1px;
}

.app-profile-content-header-right
  .app-profile-content-header-right-profile-profession {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 150%;
}

.app-profile-content-header-right-profile-profession .app-profile-speciality {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.4rem;
}

.app-profile-content-header-right-profile-experience {
  font-size: 1.8rem;
  color: var(--color-primary);
  letter-spacing: 2px;
}

.app-profile-content-header-right-profile-experience span {
  font-size: 1.4rem;
  color: var(--color-text);
  letter-spacing: 1px;
  font-weight: lighter;
  margin-left: 2rem;
}

.app-profile-content-header-right-profile-location .phone-icon {
  font-size: 1.3rem;
  color: var(--color-primary);
  font-weight: lighter;
}

.app-profile-content-biography {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem;
  padding: 0 5%;
}

.app-profile-content-biography .app-profile-content-biography-header h3,
.app-profile-content-biography-contact h3 {
  font-size: 2rem;
  font-weight: 300;
  color: var(--color-primary);
  margin: 1rem 0;
}

.app-profile-content-biography .app-profile-content-biography-header p {
  width: 80%;
  font-size: 1.3rem;
  font-weight: lighter;
  color: var(--color-text);
  letter-spacing: 1px;
}

.app-profile-content-biography-contact
  .app-profile-content-biography-contact-one {
  display: flex;
  gap: 1.5rem;
  margin: 1rem 0;
}

.app-profile-content-biography-contact-one
  .app-profile-content-biography-contact-one-left
  p, .doctor-address .address-icon {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-primary);
}

.app-profile-content-biography-contact-one
  .app-profile-content-biography-contact-one-right
  p, .doctor-address .address-location {
  font-size: 1.3rem;
  font-weight: lighter;
  color: var(--color-text);
  letter-spacing: 0.6px;
}
