.footer {
  display: grid;
  place-items: center;
  padding: 7rem 2rem;
  background: #f5fbff;
}


/* .footer-container {
  max-width: 1250px;
} */

.footer-row {
  display: flex;
  flex-wrap: wrap;

  /* gap: 12rem; */
}

.footer-column {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.column-content h2 {
  color: #000;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Nunito Sans", sans-serif;
  margin-left: -2rem;
}

.column-content p {
  color: rgb(0 0 0 / 70%);
  font-family: "Nunito Sans", sans-serif;
}

.column-content ul {
  margin-left: 2rem;
}

.column-content .footer-link {
  display: flex;
}

.column-content .footer-link li {
  margin: 0 30px 0 0;
  color: #919191;
}

.column-content .footer-link li a {
  font-size: 15px;
  font-weight: 400;
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  background: transparent;
  border-radius: 50%;
  margin-right: 7px;
  line-height: 42px !important;
  border: 1px solid #e8e8e8;
}

.column-content .questions{
  margin-left: 5rem;
}

.column-content .footer-link li a i {
  font-size: 20px;
  color: #fd955d;
}

.column-content ul li {
  padding-bottom: 10px;
  margin-left: 4rem;
}

.column-content ul li a {
  color: rgb(0 0 0 / 80%);
  font-family: "Nunito Sans", sans-serif;
}

.column-content ul li a span {
  color: #fd955d;
  padding-right: 5px;
}

.column-content ul li span {
  color: rgb(0 0 0 / 70%);
  font-family: "Nunito Sans", sans-serif;
}

.column-content ul .list {
  display: table;
  line-height: 1.5;
  margin-bottom: 15px;
}

.list .number {
  color: #fd955d;
  font-family: "Nunito Sans", sans-serif;
}

.list .email {
  color: #fd955d;
  font-family: "Nunito Sans", sans-serif;
}

.column-content ul .footer-icon {
  display: table-cell;
  vertical-align: top;
  width: 40px;
  font-size: 18px;
  padding-top: 2px;
  color: #fd955d;
}

.column-content ul .text {
  display: table-cell;
  vertical-align: top;
  font-family: "Nunito Sans", sans-serif;
}

.footer-2 {
  padding: 2rem 0;
  display: grid;
  place-items: center;
}

@media screen and (width <=768px) {
  .footer {
    display: flex;
    padding: 3rem 2rem;
  }

  .footer-row {
    display: flex;
    flex-direction: column;
  }

  .footer-column {
    padding-top: 2rem;
  }
}
