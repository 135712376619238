.blogs-container {
  position: relative;
}

.blogs-container .nav-bg {
  top: 0;
  left: 0;
  height: 75px;
  background-color: #2c2a2f;
}

.blogsSphere {
  padding: 6rem 4rem;
}

.blogsSphere .blogs-search-input input {
  width: 100%;
  height: 3.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 0 1rem;
  font-size: 1.2rem;
  outline: none;
}

.blogs-search-input .search-icon {
  position: relative;
  font-size: 2rem;
  top: -2.5rem;
  left: 85%;
  color: var(--color-primary);
  cursor: pointer;
}
.recent-blogs {
  margin-top: 2rem;
  background-color: var(--color-text-light);
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  width: 100%;
  padding: 2rem 3rem;
}

.recent-blogs .recent-blogs-heading h3 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  padding-bottom: 1rem;
  border-bottom: 2px solid #ccc;
}

.recent-blogs .recent-blogs-list ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
  padding-left: 1.2em;
}

.recent-blogs .recent-blogs-list ul li {
  position: relative;
}

.recent-blogs .recent-blogs-list ul li a {
  text-decoration: none;
  color: rgb(0 0 238);
  margin-bottom: 5px;
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
}

.recent-blogs .recent-blogs-list ul li::before {
  content: "";
  position: absolute;
  top: 0.3em;
  left: -5%;
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background-color: #000;
}


.blogs-heading {
  display: flex;
  gap: 7rem;
}

.blogs-body-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.blogs-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  height: 525px;
}

.blog-card-text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-card-text p {
  margin-bottom: 1rem;
  font-size: 1rem;
  color: #333;
  letter-spacing: 0.4px;
  line-height: 1.3em;
  max-height: 5.4em;
  overflow: hidden;
}

.blogs-card-image img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: auto;
  border-radius: 5px;
}

.blogs-card-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.3rem;
  margin: 5px 0;
  font-weight: bold;
  letter-spacing: 1px;
}

.blogs-card-button {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  padding: 0.8rem 1rem;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
  margin: 0 0 10px;
  cursor: pointer;
}

.blogs-card-button:hover {
  background-color: var(--color-primary);
  color: #fff;
}

/* ===== Media Queries ===== */

@media screen and (max-width: 1200px) {
  .blogs-heading {
    flex-direction: column;
    gap: 1rem;
  
  }

  .blogs-body-content {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media screen and (max-width: 375px) {
  .blogs-container {
    position: relative;
  }

  .blogs-container .nav-bg {
    height: 50px;
    padding: 0 0.5rem;
    font-size: 1rem;
  }

  .blogs-search-input .search-icon {
    font-size: 1.5rem;
    top: -2rem;
    left: 80%;
  }

  .recent-blogs {
    margin-top: 1rem;
    padding-bottom: 0.5rem;
  }

  .recent-blogs .recent-blogs-list ul {
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 1em;
  }

  .recent-blogs .recent-blogs-list ul li a {
    font-size: 0.8rem;
  }

  .blogs-heading {
    display: flex;
    gap: 3.5rem;
  }

  .blogs-body-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .blogs-card {
    width: 200%;
    border: 1px solid red;
    margin-bottom: 10px;
    padding: 5px;
    height: auto;
  }

  .blog-card-text p {
    font-size: 0.8rem;
    line-height: 1.2em;
    max-height: 4.8em;
  }

  .blogs-card-image img {
    width: 100%;
    height: auto;
  }

  .blogs-card-title {
    font-size: 1rem;
    margin: 2.5px 0;
  }

  .blogs-card-button {
    margin: 0 0 5px;
  }
}

@media screen and (max-width: 450px) {
  .blogs-container .nav-bg {
    font-size: 1rem;
    padding: 0 0.5rem;
  }

  .blogs-search-input .search-icon {
    left: 80%;
    font-size: 1.5rem;
  }

  .recent-blogs {
    margin-top: 1rem;
  }

  .recent-blogs .recent-blogs-list ul {
    flex-direction: column;
    gap: 0.5rem;
  }

  .recent-blogs .recent-blogs-list ul li a {
    font-size: 0.8rem;
  }

  .blogs-heading {
    flex-direction: column;
    gap: 3.5rem;
  }

  .blogs-body-content {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .blogs-card {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    height: auto;
  }

  .blog-card-text p {
    font-size: 0.8rem;
    line-height: 1.2em;
    max-height: 4.8em;
  }

  .blogs-card-title {
    font-size: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .blogs-card {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .blogs-card {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .blogs-card {
    width: 100%;
  }
}

@media screen and (max-width: 320px) {
  .blogs-card {
    width: 100%;
  }
}

