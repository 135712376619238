.app-patient-testimanial-container {
  margin-top: 2rem;
}

.app-patient-testimanial-header h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-primary);
}

.my-swiper {
  width: 80%;
}

.app-patient-testimanial-swiper {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.app-patient-testimanial-card {
  background-color: var(--color-background-color);
  border-radius: var(--border-radius);
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  text-align: center;
  align-items: center;
}

.app-patient-testimanial-card-image img {
  border-radius: 60px;
  border: 1px solid var(--color-primary);
  width: 8rem;
  height: 8rem;
  margin-top: 3rem;
}

.app-patient-testimanial-card-body {
  padding: 0 4rem;
  max-width: 90%;
  margin-left: 1rem;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--color-primary);
  width: 40px;
  height: 40px;
}

.app-patient-testimanial-card-body-name h3 {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-primary);
  letter-spacing: 2px;
}

.app-patient-testimanial-card-body .quote-icon {
  color: var(--color-secondary);
}

.app-patient-testimanial-card-body-icon-left {
  margin-top: 1rem;
  font-size: 2rem;
  display: flex;
}

.app-patient-testimanial-card-body-icon-right {
  margin-top: 1rem;
  font-size: 2rem;
  display: flex;
  justify-content: flex-end;
}

.app-patient-testimanial-card-body-txt {
  margin-top: 2rem;
  font-size: 1.2rem;
  font-weight: 400;
  max-width: max-content;
  color: var(--color-text);
}
