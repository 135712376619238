.search-bar {
  width: 300px;
  height: 50px;
  border: 2px solid var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
}

.search-input {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color:  #fff;
  padding: 5px;
  box-sizing: border-box;
}

.search-input .input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 16px;
}

.search-icon {
  margin-left: 10px;
  color: var(--color-primary);
  font-size: 20px;
}

/* ===== Media queries ===== */

@media screen and (width <= 768px) {
  .search-bar {
    width: 100%;
  }
}
