.accordion {
  padding-top: 6rem;
  padding-bottom: 5rem;
  display: grid;
  place-items: center;
}

.accordion-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1140px;
}

.left {
  padding-right: 4rem;
  width: 50%;
}

.left h3 {
  font-size: 30px;
  font-weight: 300;
  font-family: "Nuosu SIL", serif;
  color: #414243;
  margin-bottom: 1rem;
}

.left-content {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #e8e8e8;
  padding-top: 2rem;
  padding-bottom: 1rem;
}

.left-content .Collapsible {
  cursor: pointer;
  font-size: 15px;
  line-height: 28px;
  color: #727272;
  font-family: Poppins, sans-serif;
}

.fa-angle-down {
  padding-right: 10px;
}

.fa-angle-up {
  padding-right: 10px;
}

.content-show {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  color: #727272;
  font-weight: 400;
  line-height: 28px;
}

.right {
  border-radius: 10px;
  overflow: hidden;
  padding-left: 4rem;
}

.right img {
  width: 450px;
  height: 450px;
  border-radius: 10px;
}

@media screen and (width <=1200px) {
  .left {
    padding-right: 3rem;
    width: 50%;
  }

  .right {
    padding-left: 3rem;
  }

  .right img {
    width: 350px;
    height: 390px;
  }
}

@media screen and (width <=968px) {
  .accordion-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .left {
    padding: 0;
    width: 90%;
  }

  .right {
    padding: 3rem 0 0;
  }

  .right img {
    width: 500px;
  }
}

@media screen and (width <=768px) {
  .accordion {
    padding-top: 2rem;
    padding-bottom: 2rem;
    display: grid;
    place-items: center;
  }

  .accordion-row {
    padding-left: 2rem;
    padding-right: 2em;
  }

  .right img {
    width: 400px;
  }
}

@media screen and (width <=476px) {
  .accordion-row {
    padding-left: 1rem;
    padding-right: 1em;
  }

  .left h3 {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .right img {
    width: 350px;
  }
}

@media screen and (width <=384px) {
  .right img {
    width: 300px;
    height: 300px;
  }

  .left-content .Collapsible {
    font-size: 14px;
  }
}

@media screen and (width <=334px) {
  .right img {
    width: 250px;
    height: 300px;
  }
}
