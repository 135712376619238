/* stylelint-disable selector-class-pattern */
.about {
  display: flex;
  position: relative;
  z-index: 1;
  margin-bottom: 10vh;
}

.about-left {
  flex: 11;
  width: 100%;
  height: 80vh;
}

.aboutimg {
  width: 100%;
  height: 100%;
}

.about-right {
  flex: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-right-end {
  right: 0;
  bottom: 0;
  width: 26.65%;
  background: #ffa611;
  line-height: 100px;
  padding: 0 95px;
  position: absolute;
  font-size: 20px;
  color: white;
}

.about-right-middle {
  display: flex;
  flex-direction: column;
}

.about-right-middle h1 {
  font-size: 50px;
  padding-bottom: 10px;
  padding-left: 10px;
  font-weight: 600;
}

.about-right-middle p {
  font-size: 17px;
  color: #727272;
  font-family: Poppins, sans-serif;
  font-weight: 450;
  padding-left: 10px;
}

.about-right-end a {
  color: white;
}

.about-right-end a:hover {
  color: dimgray;
}

.story {
  display: flex;
  margin: 50px;
}

.story-left {
  flex: 6;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.story-head {
  font-size: 50px;
}

.story-right {
  flex: 6;
  margin-top: 10%;
  margin-left: 5vw;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.story-head span {
  color: #ffa611;
}

.storyImg {
  width: 100%;
  height: 100%;
  margin: 20px 0;
}

.story-right span {
  font-size: 32px;
  color: #001d38;
  font-family: Poppins, sans-serif;
}

.story-right p {
  font-size: 16px;
  color: #727272;
  font-family: Poppins, sans-serif;
}

.benefit {
  display: flex;
  margin: 30px 0;
}

.benefit-left {
  flex: 6;
  margin: 0 50px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.benefit-right {
  flex: 6;
}

.benefit-right img {
  width: 100%;
  height: 100%;
}

.benefit-left h1 {
  font-size: 50px;
  padding-left: 10px;
  font-weight: 600;
  margin-bottom: 20px;
}

.benefit-left h1 span {
  color: #ffa611;
}

.benefit-left p {
  font-size: 17px;
  color: #727272;
  font-family: Poppins, sans-serif;
}

@media screen and (width <=1024px) {
  .about {
    flex-direction: column;
  }

  .about-right {
    flex-direction: column;
    row-gap: 20px;
  }

  .about-right-end {
    position: relative;
    width: 100%;
    line-height: 80px;
  }

  .about-right-middle {
    justify-content: center;
    align-items: center;
    margin: 10px 30px;
    flex-direction: column;
  }

  .story {
    flex-direction: column;
  }

  .story-right span {
    align-self: center;
  }

  .storyImg {
    display: flex;
  }

  .benefit {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .benefitImg {
    display: none;
  }
}
