.main-profile {
  width: 100%;
}

.profile-banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
  height: 80px;
  margin: 0;
  padding: 0;
  border-radius: 15px;
  border-width: 50px;
  border: 1px solid #fdae5c;
  background-color: #2c2a2f;
}




.profile-top {
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
}

.profile-container {
  margin-left: 50px;
  display: flex;
  align-items: end;
  gap: 30px;
  position: relative;
}

.profile-container .profile-image {
  position: relative;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 5px solid #fdae5c;
}

.profile-image img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

.modal-form {
  display: flex;
  flex-direction: row;
}

.modal-form button {
  margin-left: 30px;
  padding: 6px;
  color: white;
  background-color: #f89b38;
  border-radius: 5px;
}

.card-button {
  padding: 7px;
  color: white;
  background-color: #f89b38;
  border-radius: 5px;

}

.event-card-image div {
  margin-left: 10px;
  margin-top: 10px;
}

.in-modal img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 20%;
  max-height: 20%;
  border-radius: 50%;
  margin-top: 10%;
}

.profile-details {
  align-content: center;
}

.profile-details h3 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.profile-details p {
  margin: 5px 0;
  font-size: 16px;
}

.notification {
  margin-right: 100px;
  display: flex;
  gap: 20px;
}

.notification-icon,
.settings-icon {
  font-size: 28px;
  cursor: pointer;
  color: #f89b38;
}

.profile-container label {
  position: absolute;
  font-size: 20px;
  background: white;
  width: 18px;
  height: 18px;
  right: 10px;
  bottom: 8px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-container label [type="file"] {
  display: none;
}

section.fetching-appointment {
  width: 90%;
  margin: 5%;
}

.blog-event {
  display: grid;
  grid-template-columns: 65% 35%;
  grid-gap: 30px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 2%;
}

.profile-blog,
.profile-event {
  padding: 20px;
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.profile-blog {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 2%;
}

.profile-blog h2,
.profile-event h2 {
  color: #333;
}

.profile-blog p,
.profile-event p {
  color: #666;
  margin-top: 10px;
}

.fetching-pagination-apt {
  border: none;
  padding: 20px;
  font-size: 17px;
  background-color: transparent;
  color: #fdae5c;
  border-bottom: 2px solid #fdae5c;
  cursor: pointer;
  transition: 0.3s;
}

.event-card-image,
.blog-card-image {
  width: 100%;
  height: 100%;
  overflow: hidden;

}

.event-card-image img,
.blog-card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

@media screen and (width <= 750px) {
  .profile-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin-left: 5%;
  }

  .profile-container .profile-image {
    height: 120px;
    width: 120px;
  }





  .blog-event {
    grid-template-columns: 100%;
  }

  .profile-blog,
  .profile-event {
    padding: 15px;
  }
}

@media screen and (width <= 480px) {
  .profile-container .profile-image {
    height: 100px;
    width: 100px;
    border-width: 3px;
  }

  .profile-details h3 {
    font-size: 20px;
  }

  .profile-details p {
    font-size: 14px;
  }
}