.logo {
  margin: 16px;
  padding: 5px 10px;
  display: flex;
  font-weight: 700;
  color: white;
  font-family: Merriweather, serif;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  background: rgb(255 255 255 / 20%);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  border: none;
  background: transparent;
  padding: 3px 20%;
  font-size: 18px;
  cursor: pointer;
}

.dropdown-menu {
  cursor: pointer;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 8px;
  list-style: none;
  display: none;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

