.app-homepage-container {
  padding: 4rem 6rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8rem;
}

.app-homepage-container .app-homepage-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.app-homepage-header {
  margin-top: 3rem;
}

.app-homepage-text {
  margin-top: 1rem;
  line-height: 1.8;
  font-size: 1.3rem;
  color: var(--color-text);
}

.app-homepage-text a {
  color: var(--color-primary);
}

.app-homepage-content button {
  background-color: var(--color-text-light);
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
  border-radius: var(--border-radius);
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: height 0.3s ease-in-out;
}


.app-homepage-title p {
  font-size: 1.3rem;
  letter-spacing: 1px;
  color: var(--color-text);
  margin-bottom: 1rem;
}

.app-homepage-button {
  margin-top: 20px;
}

.app-homepage-button .app-homepage-btn {
  font-size: 1.5rem;
  font-weight: 700;
}

.app-homepage-button .app-homepage-btn:hover {
  background-color: var(--color-primary);
  color: var(--color-text-light);
  transition: height 0.3s ease-in-out;
}

/* ===== Media queries ===== */

@media (width <= 1024px) {
  .app-homepage-container {
    grid-template-columns: 1fr;
    grid-gap: 4rem;
    padding: 2rem 3rem;
  }
}

@media (width <= 768px) {
  .app-homepage-container {
    padding: 2rem 1.5rem;
  }

  .app-homepage-title p {
    font-size: 1.5rem;
    letter-spacing: 2px;
    line-height: 2.2rem;
  }
}

@media (width <= 480px) {
  .app-homepage-container {
    padding: 0 -6rem;
  }
}
